jQuery(document).ready(function(){
  jQuery('select').find('option[value=""]').attr('selected','selected');
});


jQuery(window).on("scroll", function() {
    if
    (jQuery(window).scrollTop() > 70){
      jQuery(".navbar-brand").addClass("shrink");
      jQuery("header#wrapper-navbar").addClass("shrink");
      jQuery("#navbarNavDropdown .dropdown-toggle + .dropdown-menu").addClass("shrink");
      jQuery('.offcanvas-collapse').addClass('shrink');
      jQuery('.site').addClass('shrink');
    }
    else
    {
        jQuery(".navbar-brand").removeClass("shrink");
        jQuery("header#wrapper-navbar").removeClass("shrink");
        jQuery("#navbarNavDropdown .dropdown-toggle + .dropdown-menu").removeClass("shrink");
        jQuery('.offcanvas-collapse').removeClass('shrink');
        jQuery('.site').removeClass('shrink');
    }
  }
);

/*
(function mainScript() {
  "use strict";
  const offcanvasToggle = document.querySelector(
    '[data-bs-toggle="offcanvas"]',
  );
  const offcanvasCollapse = document.querySelector(".offcanvas-collapse");
  offcanvasToggle.addEventListener("click", function () {
    offcanvasCollapse.classList.toggle("open");
  });
})();
*/

var icon = document.getElementById("icon");
var icon1 = document.getElementById("a");
var icon2 = document.getElementById("b");
var icon3 = document.getElementById("c");
var nav = document.getElementById('nav');
var blue = document.getElementById("blue");
//var logins = document.getElementById("logins");

icon.addEventListener("click", function() {
  icon1.classList.toggle('a');
  icon2.classList.toggle('c');
  icon3.classList.toggle('b');
  nav.classList.toggle('show');
  //logins.classList.toggle('show');
  blue.classList.toggle('slide');
});

// using classList.toggle() method
const para = document.querySelector('.navbar-toggler');

icon.addEventListener("click",function showLogins() {
  document.addEventListener('DOMContentLoaded', function() {
    var logins = document.getElementById('logins');
    if (logins) {
        // Your code that uses the 'logins' element
    } else {
        console.error('Element with ID "logins" not found.');
    }
  });
}); 

 // Add slideDown animation to Bootstrap dropdown when expanding.
   const mq = window.matchMedia( "(max-width: 991px)" ); 
  if (mq.matches) {
  // window width is at least Xpx
 jQuery('.dropdown').on('show.bs.dropdown', function() {
    jQuery(this).find('.dropdown-menu').first().stop(true, true).slideDown();
  });

  // Add slideUp animation to Bootstrap dropdown when collapsing.
  jQuery('.dropdown').on('hide.bs.dropdown', function() {
    jQuery(this).find('.dropdown-menu').first().stop(true, true).slideUp();
  });
}

jQuery("#thumbs button").on('click', function(){
    jQuery(this).siblings().removeClass('active')
    jQuery(this).addClass('active');
})


jQuery('.fas').addClass('fa');


//SlickSliders


jQuery(function() {
  jQuery('#slider').slick({
  dots: false,
  infinite: true,
  slidesToShow: 1,
  adaptiveHeight: true,
  asNavFor: '#carousel, #captions',
  });
  jQuery('#captions').slick({
  dots: false,
  arrows: false,
  infinite: true,
  slidesToShow: 1,
  asNavFor: '#slider, #carousel',
  responsive: [
    {
      breakpoint: 992,
      settings: {
        arrows: true,
      }
    }
  ]
  });
  jQuery('#carousel').slick({
  dots: false,
  infinite: true,
  focusOnSelect: true,
  arrows: true,
  slidesToShow: 5,
  slidesToScroll: 1,
  vertical: true, 
  verticalSwiping:true,
  asNavFor: '#slider, #captions',
  responsive: [
    {
      breakpoint: 1550,
      settings: {
        slidesToShow: 5,
        vertical: true, 
        verticalSwiping:true,
      }
    },
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 4,
        vertical: true, 
        verticalSwiping:true,
      }
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 5,
        arrows: false,
        vertical: false, 
        verticalSwiping:false,
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
        arrows: false,
        vertical: false, 
        verticalSwiping:false,
      }
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 3,
        arrows: false,
        vertical: false, 
        verticalSwiping:false,
      }
    },
    {
      breakpoint: 420,
      settings: {
        slidesToShow: 3,
        arrows: false,
        vertical: false, 
        verticalSwiping:false,
      }
    }
  ]
  });
  jQuery('.thumb-scroller').slick({
    dots: true,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 5,
    responsive: [
      {
        breakpoint: 2400,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 1980,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]
  });
});

jQuery('#product-categories.dropdown, #solution-categories.dropdown').on('show.bs.dropdown', function() {
  jQuery(this).find('.dropdown-menu').first().stop(true, true).slideDown();
});

// Add slideUp animation to Bootstrap dropdown when collapsing.
jQuery('#product-categories.dropdown, #solution-categories.dropdown').on('hide.bs.dropdown', function() {
  jQuery(this).find('.dropdown-menu').first().stop(true, true).slideUp();
});


AOS.init();


function reveal() {
  var reveals = document.querySelectorAll(".border-bottom-grow",".border-top-grow");
  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 10;
    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("animated");
    } else {
      reveals[i].classList.remove("animated");
    }
  }
}

window.addEventListener("scroll", reveal);

// To check the scroll position on page load
reveal();


//Mega Menu
jQuery(document).ready(function (jQuery) {

  //const mq = window.matchMedia( "(min-width: 992px)" ); 
  //if (mq.matches) {
  // window width is at least 500px
    const wrapNavigationMenus = document.querySelectorAll("#main-menu > li");

    /* Create new structure with the current and new elements */

    wrapNavigationMenus.forEach((menu) => {
      const listElements = menu.querySelectorAll(
        ".dropdown-toggle + ul.dropdown-menu > li"
      );

      const wrapper = document.createElement("div");
      wrapper.classList.add("navigation");
      const ulWrapper = menu.querySelector(".dropdown-toggle + ul.dropdown-menu");

      if (ulWrapper) {
        const detailsContainer = document.createElement("div");
        detailsContainer.classList.add("details-container");

        const mainMenuTitle = document.createElement("div");
        mainMenuTitle.classList.add("main-menu-title");

        const mainMenuLink = document.createElement("a");
        mainMenuTitle.appendChild(mainMenuLink);

        const mainMenuDescription = document.createElement("div");
        mainMenuDescription.classList.add("main-menu-description");

        const openMenuThirdColumn = document.createElement("div");
        openMenuThirdColumn.classList.add("open-menu-third-column");

        detailsContainer.appendChild(mainMenuTitle);
        detailsContainer.appendChild(mainMenuDescription);

        ulWrapper.appendChild(detailsContainer);
        ulWrapper.appendChild(openMenuThirdColumn);
        ulWrapper.appendChild(wrapper);
      }

      listElements.forEach((listElement) => {
        wrapper.appendChild(listElement);
      });
    });

    const allMenuItems = document.querySelectorAll(".navigation > .nav-item");
    const hoverMenus = document.querySelectorAll(
      ".navigation > .nav-item.menu-item-has-children"
    );

    const allNavigationColumns = document.querySelectorAll(
      "#navbarNavDropdown .navigation"
    );

    /* Classify two types of menu variations */

    allNavigationColumns.forEach((item) => {
      const navigationDropdown = item.querySelector("ul.dropdown-menu");
      if (navigationDropdown) {
        item.closest("li.menu-item").classList.add("menu-variation-1");
      } else {
        item.closest("li.menu-item").classList.add("menu-variation-2");
      }
    });

    const allMenuDescriptions = document.querySelectorAll(
      ".menu-variation-2 li.menu-item"
    );

    allMenuDescriptions.forEach((el) => {
      let menuDescription = document.createElement("p");
      menuDescription.classList.add("menu-description");
      el.appendChild(menuDescription);
    });

    function hideOtherGrandChildMenus() {
      allMenuItems.forEach((hoverEl) => {
        if (hoverEl.classList.contains("menu-item-has-children")) {
          hoverEl.querySelector("ul").classList.remove("show");
        }
      });
    }

    function resetThirdColumn() {
      hoverMenus.forEach((el) => {
        el.querySelector("ul").classList.remove("show");
      });
    }

    const navigationElements = document.querySelectorAll(".navigation > li");

    navigationElements.forEach((el) => {
      el.addEventListener("mouseover", () => {
        resetThirdColumn();
      });
    });

    /* Show the grandchild items on hover */
    
    hoverMenus.forEach((hoverEl) => {
      hoverEl.addEventListener("mouseover", (el) => {
        hideOtherGrandChildMenus();
        let grandChildMenuToShow = el.currentTarget.querySelector("ul");
        grandChildMenuToShow.classList.add("show");
      });
    });

    /* Set the main menu title */

    const allMenusWithChildren = document.querySelectorAll(
      "#main-menu > .menu-item-has-children"
    );

    let menuData = [];
    let menuDataToFilter = [];

    let pageData = [];

    /* Set the main menu title */

    allMenusWithChildren.forEach((menu, index) => {
      const number = menu.id.split("-");

      menuDataToFilter.push(number[number.length - 1]);

      allMenusWithChildren[index].querySelector(
        ".details-container .main-menu-title a"
      ).innerText = allMenusWithChildren[index]
        .querySelector("a")
        .getAttribute("title");
    });

    // Set the main menu descriptions by fetching page excerpts

    fetch("/wp-json/wp/v2/navigation/2")
      .then((res) => res.json())
      .then((data) => {
        menuData = data;

        for (let i = 0; i < menuData.length; i++) {
            for (let j = 0; j < menuDataToFilter.length; j++) {
            if (menuData[i].ID == menuDataToFilter[j]) {
              pageData.push(Number(menuData[i].object_id));
            }
          }
        }

        fetchPageData(pageData);
      });

    let pageExcerpts = [];

    function fetchPageData(data) {
      let urls = [];
      data.forEach((page, index) => {
        urls.push(`/wp-json/wp/v2/pages/${page}`);
      });

      // Maps each URL into a fetch() Promise
      var requests = urls.map(function (url) {
        return fetch(url).then(function (response) {
          return response.json();
        });
      });

      // Resolve all the promises
      Promise.all(requests)
        .then((results) => {
          return results;
        })
        .then((data) => {
          data.forEach((result, index) => {
            pageExcerpts.push({ page: page, data: result.excerpt.rendered, link: result.link });
            if (index >= data.length - 1) {
              renderExcerpts(pageExcerpts);
            }
          });
        });
    }

    function renderExcerpts(excerpts) {
      excerpts.forEach((excerpt, index) => {
        allMenusWithChildren[index].querySelector(
          ".main-menu-description"
        ).innerHTML = excerpt.data;
      });

      excerpts.forEach((excerpt, index) => {
        allMenusWithChildren[index].querySelector(
          ".main-menu-title a"
          //".details-container a"
        ).setAttribute("href", excerpt.link);
      });
      subMenuSetup();
    }

    /* Set the sub menu descriptions */

    function subMenuSetup() {
      let subMenuDataToFilter = [];

      const allSubMenusWithChildren = document.querySelectorAll(
        "#main-menu .menu-variation-2 .navigation li.menu-item-object-page"
      );

      allSubMenusWithChildren.forEach((menu) => {
        const number = menu.id.split("-");
        subMenuDataToFilter.push(number[number.length - 1]);
      });

      let subPageData = [];

      for (let i = 0; i < menuData.length; i++) {
        for (let j = 0; j < subMenuDataToFilter.length; j++) {
          if (menuData[i].ID == subMenuDataToFilter[j]) {
            subPageData.push(Number(menuData[i].object_id));
          }
        }
      }

      subPageExcerptsFetch(subPageData);

      let subPageExcerpts = [];

      function subPageExcerptsFetch(data) {
        let subPageurls = [];
        data.forEach((page, index) => {
          subPageurls.push(`/wp-json/wp/v2/pages/${page}`);
        });

        // Maps each URL into a fetch() Promise
        var requests = subPageurls.map(function (url) {
          return fetch(url).then(function (response) {
            return response.json();
          });
        });

        // Resolve all the promises
        Promise.all(requests)
          .then((results) => {
            return results;
          })
          .then((data) => {
            data.forEach((result, index) => {
              subPageExcerpts.push({ page: page, data: result.excerpt.rendered });
              if (index >= data.length - 1) {
                renderSubPageExcerpts(subPageExcerpts);
              }
            });
          });
      }

      function renderSubPageExcerpts(data) {
        data.forEach((excerpt, index) => {
          allSubMenusWithChildren[index].querySelector(
            ".menu-description"
          ).innerHTML = excerpt.data;
        });
      }
    }
  //} else {
    // window width is less than 500px
  //}
});